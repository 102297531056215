import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Sobre nós | Sobre o Fairway Legends Golf Club
			</title>
			<meta name={"description"} content={"O Fairway Legends Golf Club tem sido um marco na comunidade do golfe durante décadas, evoluindo de um simples projeto de paixão para um destino de golfe de primeira linha."} />
			<meta property={"og:title"} content={"Sobre nós | Sobre o Fairway Legends Golf Club"} />
			<meta property={"og:description"} content={"O Fairway Legends Golf Club tem sido um marco na comunidade do golfe durante décadas, evoluindo de um simples projeto de paixão para um destino de golfe de primeira linha."} />
			<meta property={"og:image"} content={"https://frotpayess.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://frotpayess.com/img/45221123.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://frotpayess.com/img/45221123.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://frotpayess.com/img/45221123.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://frotpayess.com/img/45221123.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://frotpayess.com/img/45221123.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://frotpayess.com/img/45221123.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Sobre nós
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				O Fairway Legends Golf Club tem sido um marco na comunidade do golfe durante décadas, evoluindo de um simples projeto de paixão para um destino de golfe de primeira linha. A história do nosso clube baseia-se no amor pelo jogo, no compromisso com a excelência e num profundo respeito pelas ricas tradições do golfe.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					A nossa filosofia
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					No coração do Fairway Legends está a convicção de que o golfe é mais do que um desporto - é uma experiência que molda o carácter e forja amizades. Esforçamo-nos por proporcionar um ambiente que honre a integridade do jogo, ao mesmo tempo que oferecemos uma abordagem moderna à excelência do golfe.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://frotpayess.com/img/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-5">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				A experiência Fairway Legends
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 70px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://frotpayess.com/img/5.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						object-fit="cover"
						sm-height="220px"
						height="600px"
						lg-height="400px"
						md-width="100%"
						md-height="450px"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Uma herança rica: A história do nosso clube está presente em cada fairway e green, proporcionando um sentimento de pertença a todos os que aqui jogam.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 30px 0px">
						<Image
							src="https://frotpayess.com/img/6.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
							Comunidade e Ligação: O Fairway Legends é mais do que um clube - é uma comunidade onde os membros partilham uma paixão mútua e criam laços duradouros.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Image
							src="https://frotpayess.com/img/7.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
							Inovação e Melhoria: Adoptamos continuamente novas técnicas e tecnologias para melhorar a experiência de golfe dos nossos membros.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://frotpayess.com/img/8.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						object-fit="cover"
						sm-height="220px"
						height="600px"
						lg-height="400px"
						md-width="100%"
						md-height="450px"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Comforting bowls: a collection of cozy soups and stews
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});